import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Card, CardContent, CardContentTitle } from "../CompoundComponents/Card"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import Slick from "../Slick"

const IndexService = ({ intl, data }) => {
  return (
    <Section primary medium>
      <Columns vcentered>
        <Column is12>
          <SectionTitle bottom white tcentered>
            {intl.formatMessage({
              id: `index.service.title`,
            })}
          </SectionTitle>
          <Slick slideToShow={3} slideToScroll={1} arr>
            <Card mn>
              <CardContent>
                <Link
                  to="/obstetrics_and_gynecology"
                  className="has-text-primary"
                >
                  <CardContentTitle>Акушерства-гинекология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/therapy" className="has-text-primary">
                  <CardContentTitle>Терапия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/cardiology" className="has-text-primary">
                  <CardContentTitle>Кардиология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/laboratory" className="has-text-primary">
                  <CardContentTitle>Лаборатория</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/oncology" className="has-text-primary">
                  <CardContentTitle>Онкология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/thoracic_surgery" className="has-text-primary">
                  <CardContentTitle>Торакальная хирургия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/vascular_surgery" className="has-text-primary">
                  <CardContentTitle>Сосудистая хирургия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/urology" className="has-text-primary">
                  <CardContentTitle>Урология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link
                  to="/traumatology_orthopedics"
                  className="has-text-primary"
                >
                  <CardContentTitle>Травматология-ортопедия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/neurology" className="has-text-primary">
                  <CardContentTitle>Неврология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/general_surgery" className="has-text-primary">
                  <CardContentTitle>Общая хирургия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/otorhinolaryngology" className="has-text-primary">
                  <CardContentTitle>Оториноларингология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/functional_diagnostics" className="has-text-primary">
                  <CardContentTitle>
                    Функциональная диагностика
                  </CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/radiology" className="has-text-primary">
                  <CardContentTitle>Радиология</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/resuscitation" className="has-text-primary">
                  <CardContentTitle>Реанимация</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/physiotherapy" className="has-text-primary">
                  <CardContentTitle>Физиотерапия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/radiography" className="has-text-primary">
                  <CardContentTitle>Рентгенография</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/mini_operations" className="has-text-primary">
                  <CardContentTitle>Мини операции</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/bronchoscopy" className="has-text-primary">
                  <CardContentTitle>Бронхоскопия</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
            <Card mn>
              <CardContent>
                <Link to="/ultrasound" className="has-text-primary">
                  <CardContentTitle>УЗИ</CardContentTitle>
                </Link>
              </CardContent>
            </Card>
          </Slick>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(IndexService)
