import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import ShowMoreText from "react-show-more-text"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Section, SectionTitle } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"
import Slick from "../Slick"

const IndexAdvantagesSection = ({ intl }) => {
  return (
    <Section>
      <SectionTitle tcentered bottom>
        {intl.formatMessage({
          id: `index.advantages.title`,
        })}
      </SectionTitle>
      <Slick slideToShow={3} slideToScroll={1} speed={2000} onHover dts>
        <div className="hvr-icon-pop has-text-centered p-1">
          <span className="icon is-large radius-50 has-background-light mb-1">
            <FontAwesomeIcon
              className="hvr-icon"
              icon="file-medical"
              size="2x"
              color="#F98B90"
            />
          </span>
          <p className="block has-text-weight-bold is-size-6-desktop mb-1">
            {intl.formatMessage({
              id: `index.advantages.list.item-1.title`,
            })}
          </p>
          <div className="block">
            <ShowMoreText
              lines={1}
              more={intl.formatMessage({ id: "button.more" })}
              less="Скрыть"
              className="content-css is-size-7-mobile has-family-nunito"
              anchorClass="my-anchor-css-className has-text-primary"
              expanded={false}
              width={280}
            >
              {intl.formatMessage({
                id: `index.advantages.list.item-1.desc`,
              })}
            </ShowMoreText>
          </div>
        </div>
        <div className="hvr-icon-pop has-text-centered p-1">
          <span className="icon is-large radius-50 has-background-light mb-1">
            <FontAwesomeIcon
              className="hvr-icon"
              icon="graduation-cap"
              size="2x"
              color="#f0de88"
            />
          </span>
          <p className="block has-text-weight-bold is-size-6-desktop mb-1">
            {intl.formatMessage({
              id: `index.advantages.list.item-2.title`,
            })}
          </p>
          <div className="block">
            <ShowMoreText
              lines={1}
              more={intl.formatMessage({ id: "button.more" })}
              less="Скрыть"
              className="content-css is-size-7-mobile has-family-nunito"
              anchorClass="my-anchor-css-className has-text-primary"
              expanded={false}
              width={280}
            >
              {intl.formatMessage({
                id: `index.advantages.list.item-2.desc`,
              })}
            </ShowMoreText>
          </div>
        </div>
        <div className="hvr-icon-pop has-text-centered p-1">
          <span className="icon is-large radius-50 has-background-light mb-1">
            <FontAwesomeIcon
              className="hvr-icon"
              icon="briefcase-medical"
              size="2x"
              color="#6b9151"
            />
          </span>
          <p className="block has-family-pt-sans has-text-weight-bold is-size-6-desktop mb-1">
            {intl.formatMessage({
              id: `index.advantages.list.item-3.title`,
            })}
          </p>
          <div className="block">
            <ShowMoreText
              lines={1}
              more={intl.formatMessage({ id: "button.more" })}
              less="Скрыть"
              className="content-css is-size-7-mobile has-family-nunito"
              anchorClass="my-anchor-css-className has-text-primary"
              expanded={false}
              width={280}
            >
              {intl.formatMessage({
                id: `index.advantages.list.item-3.desc`,
              })}
            </ShowMoreText>
          </div>
        </div>
        <div className="hvr-icon-pop has-text-centered p-1">
          <span className="icon is-large radius-50 has-background-light mb-1">
            <FontAwesomeIcon
              className="hvr-icon"
              icon="handshake"
              size="2x"
              color="#8c83ec"
            />
          </span>
          <p className="block has-family-pt-sans has-text-weight-bold is-size-6-desktop mb-1">
            {intl.formatMessage({
              id: `index.advantages.list.item-4.title`,
            })}
          </p>
          <div className="block">
            <ShowMoreText
              lines={1}
              more={intl.formatMessage({ id: "button.more" })}
              less="Скрыть"
              className="content-css is-size-7-mobile has-family-nunito"
              anchorClass="my-anchor-css-className has-text-primary"
              expanded={false}
              width={280}
            >
              {intl.formatMessage({
                id: `index.advantages.list.item-4.desc`,
              })}
            </ShowMoreText>
          </div>
        </div>
      </Slick>
    </Section>
  )
}

export default injectIntl(IndexAdvantagesSection)
