import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import ShowMoreText from "react-show-more-text"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import Slick from "../Slick"

const IndexOperating = ({ intl, data, dataOperating }) => {
  return (
    <Section>
      <Columns vcentered rowReverse>
        <Column is6 offset1>
          <div className="box">
            <Slick slideToShow={1} slideToScroll={1} speed={2000}>
              {dataOperating.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>
          </div>
        </Column>
        <Column is5>
          <SectionTitle bottom>
            {intl.formatMessage({
              id: `index.operating-unit.title`,
            })}
          </SectionTitle>

          <SectionSubtitle>
            {intl.formatMessage({
              id: `index.operating-unit.desc-1`,
            })}
          </SectionSubtitle>
          <SectionSubtitle>
            {intl.formatMessage({
              id: `index.operating-unit.desc-2`,
            })}
          </SectionSubtitle>

          <div className="my-1 has-text-weight-normal has-family-nunito mb-2">
            <ShowMoreText
              lines={1}
              more={intl.formatMessage({ id: "button.more" })}
              less="Скрыть"
              className="content-css"
              anchorClass="my-anchor-css-class has-text-primary"
              expanded={false}
              width={280}
            >
              {intl.formatMessage({
                id: `index.operating-unit.desc-3`,
              })}
            </ShowMoreText>
          </div>

          <Link
            className="button is-normal is-size-7-mobile is-rounded is-uppercase has-text-weight-bold is-warning hvr-ripple-out"
            to="/booking"
          >
            {intl.formatMessage({
              id: `header.nav.item-6`,
            })}
          </Link>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(IndexOperating)
