import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"

const IndexLaboratory = ({ intl, data }) => {
  return (
    <Section>
      <Columns vcentered rowReverse>
        <Column is6 offset1>
          <div className="box">
            <Img
              fluid={data.edges[2].node.featuredImage.childImageSharp.fluid}
            />
          </div>
        </Column>
        <Column is5>
          <SectionTitle bottom>
            {intl.formatMessage({
              id: `index.laboratory.title`,
            })}
          </SectionTitle>
          <SectionSubtitle bottom>
            {intl.formatMessage({
              id: `index.laboratory.desc`,
            })}
          </SectionSubtitle>
          <Link
            className="button is-normal is-size-7-mobile is-rounded is-uppercase has-text-weight-bold is-warning hvr-ripple-out"
            to="/booking"
          >
            {intl.formatMessage({
              id: `header.nav.item-6`,
            })}
          </Link>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(IndexLaboratory)
