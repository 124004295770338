import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import HeroSection from "../components/Sections/Hero"
import SEO from "../components/SEO/index"
import StyledBackgroundSection from "../components/BackgroundSection"
import IndexAdvantagesSection from "../components/Sections/IndexAdvantagesSection"
import IndexTechnologySection from "../components/Sections/IndexTechnologySection"
import IndexOperating from "../components/Sections/IndexOperating"
import IndexInpatient from "../components/Sections/IndexInpatient"
import IndexLaboratory from "../components/Sections/IndexLaboratory"
import IndexDoctors from "../components/Sections/IndexDoctors"
import IndexService from "../components/Sections/IndexService"

import "./mystyles.scss"

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Fanomed clinics"
        description="Fanomed clinics - Медицинская клиника для обслуживания вашей семьи или компании"
      />
      <StyledBackgroundSection>
        <HeroSection location />
      </StyledBackgroundSection>
      <IndexAdvantagesSection />
      <IndexTechnologySection />
      <IndexOperating data={data.section} dataOperating={data.operating} />
      <IndexInpatient data={data.section} dataHospital={data.hospital} />
      <IndexLaboratory data={data.section} />
      <IndexDoctors data={data.doctors.edges} />
    </>
  )
}

export const IndexPageQuery = graphql`
  query {
    section: allIndexsectionsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    doctors: allDoctorsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    hospital: allHospitalYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    operating: allOperatingYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(IndexPage)
