import React from "react"
import Img from "gatsby-image"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Card,
  CardContent,
  CardContentTitle,
  CardContentSubtitle,
  CardImg,
} from "../CompoundComponents/Card"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"

const IndexDoctors = ({ data, intl }) => {
  return (
    <Section white>
      <Columns centered>
        <Column is7 centered>
          <SectionTitle>
            {intl.formatMessage({
              id: `doctors.title-index`,
            })}
          </SectionTitle>
          <SectionSubtitle>
            {intl.formatMessage({
              id: `doctors.desc`,
            })}
          </SectionSubtitle>
        </Column>
        <Column is12 centered>
          <Link
            className="button is-normal is-size-7-mobile is-rounded is-uppercase has-text-weight-bold is-warning hvr-ripple-out mb-2"
            to="/doctors"
          >
            {intl.formatMessage({
              id: `button.doctors`,
            })}
          </Link>
        </Column>
        <Column is3>
          <Link to="/thoracic_surgery">
            <Card>
              {/* <Link to={data.node.link}> */}
              <CardImg>
                <Img
                  fluid={data[8].node.featuredImage.childImageSharp.fluid}
                  alt={data[8].node.id}
                />
              </CardImg>
              {/* </Link> */}
              <CardContent>
                <CardContentTitle>
                  {intl.formatMessage({
                    id: `doctors.item-5.name`,
                  })}
                </CardContentTitle>
                <CardContentSubtitle>
                  {intl.formatMessage({
                    id: `doctors.item-5.position`,
                  })}
                </CardContentSubtitle>
              </CardContent>
            </Card>
          </Link>
        </Column>
        <Column is3>
          <Link to="/ultrasound">
            <Card>
              <CardImg>
                <Img
                  fluid={data[4].node.featuredImage.childImageSharp.fluid}
                  alt={data[4].node.id}
                />
              </CardImg>
              <CardContent>
                <CardContentTitle>
                  {intl.formatMessage({
                    id: `doctors.item-11.name`,
                  })}
                </CardContentTitle>
                <CardContentSubtitle>
                  {intl.formatMessage({
                    id: `doctors.item-11.position`,
                  })}
                </CardContentSubtitle>
              </CardContent>
            </Card>
          </Link>
        </Column>
        <Column is3>
          <Link to="/obstetrics_and_gynecology">
            <Card>
              <CardImg>
                <Img
                  fluid={data[6].node.featuredImage.childImageSharp.fluid}
                  alt={data[6].node.id}
                />
              </CardImg>
              <CardContent>
                <CardContentTitle>
                  {intl.formatMessage({
                    id: `doctors.item-1.name`,
                  })}
                </CardContentTitle>
                <CardContentSubtitle>
                  {intl.formatMessage({
                    id: `doctors.item-1.position`,
                  })}
                </CardContentSubtitle>
              </CardContent>
            </Card>
          </Link>
        </Column>
        <Column is3>
          <Link to="/resuscitation">
            <Card>
              <CardImg>
                <Img
                  fluid={data[1].node.featuredImage.childImageSharp.fluid}
                  alt={data[1].node.id}
                />
              </CardImg>
              <CardContent>
                <CardContentTitle>
                  {intl.formatMessage({
                    id: `doctors.item-12.name`,
                  })}
                </CardContentTitle>
                <CardContentSubtitle>
                  {intl.formatMessage({
                    id: `doctors.item-12.position`,
                  })}
                </CardContentSubtitle>
              </CardContent>
            </Card>
          </Link>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(IndexDoctors)
